// @ts-check
import {Backdrop} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {FAIL_EVENTS, STATES, WS_ACTIONS, WS_EVENTS} from "../../../config/event_config";
import BlockedScreen from "../../components/blocked_screen/blocked_screen";
import EnablePrintLayer from "../../components/enable_print_layer/enable_print_layer";
import OpBacklogView from "../../components/op_backlog_view/op_backlog_view";
import {OpDetailsPopover} from "../../components/op_details_popover/op_details_popover";
import {CONFLICT} from "../../components/op_edit_layer/op_edit_layer";
import {clearSaveEditOpStatusAction} from "../../components/op_edit_layer/op_edit_layer_actions";
import {selectSaveEditOpStatus, selectSaveError} from "../../components/op_edit_layer/op_edit_layer_selectors";
import {loadRoomsAction} from "../../components/rooms/rooms_actions";
import ActionMenubar from "../../components/shared/action_menubar/action_menubar";
import DetailRight from "../../components/shared/detail_right/detail_right";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import ViewSwitch from "../../components/shared/view_switch/view_switch";
import {SocketContext} from "../../contexts/websocket/websocket";
import {loadPrintDatesAction} from "../../redux/actions/print_actions";
import {selectCurrentUser, selectRefreshTrigger} from "../../redux/app_selectors";
import {selectEventId, selectEvents} from "../../redux/events/event_selectors";
import {isRejected, isResolved} from "../../redux/utils/status";
import {PERMISSION, useSecurity} from "../../utils/security";
import {loadDetailOpAction, loadStateAction, toggleBacklogView} from "../op_management/op_management_actions";
import {
    selectIsBacklogView,
    selectSessionEditedBy,
    selectSessionId,
    selectSolverEvent,
    selectStatus
} from "../op_management/op_management_selectors";
import {getBlockScreenTypeForOpBacklog} from "./helpers";
import useStyles from "./op_backlog_page.styles";

/**
 * render OpBacklogPage
 * @return {React.ReactElement}
 */
const OpBacklogPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();
    const {classes} = useStyles();
    const socket = useContext(SocketContext);

    // Redux
    const {email: userEmail, organizationId} = useSelector(selectCurrentUser);
    const isBacklogView = useSelector(selectIsBacklogView);
    const {showBlockscreen, eventCreatedAt, eventKey} = useSelector(selectEvents);
    const state = useSelector(selectStatus);
    const refreshTrigger = useSelector(selectRefreshTrigger);
    const eventId = useSelector(selectEventId);
    const sessionId = useSelector(selectSessionId);
    const stateSolverEvent = useSelector(selectSolverEvent);
    const saveStatus = useSelector(selectSaveEditOpStatus);
    const saveError = useSelector(selectSaveError);
    const editedBy = useSelector(selectSessionEditedBy);

    // States
    const [openEnablePrint, setOpenEnablePrint] = useState(false);
    const [showFullActionMenubar, setShowFullActionMenubar] = useState(false);
    const [blockScreenType, setBlockScreenType] = useState(null);
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);

    useEffect(() => {
        if (isBacklogView) {
            dispatch(loadStateAction());
            dispatch(loadRoomsAction(organizationId));
        }
    }, [isBacklogView]);

    /**
     * Set the type of the block screen from the state.
     * This logic is used in case of if a user has missed the event (the nextOR was opened after the event)
     */
    useEffect(() => {
        const key = state?.showBlockscreen ? state?.state : null;
        setBlockScreenType(getBlockScreenTypeForOpBacklog(key));
    }, [state, userEmail, editedBy]);

    /**
     *  Set the type of the block screen from the websocket event.
     */
    useEffect(() => {
        const key = showBlockscreen ? eventKey : null;

        setBlockScreenType(getBlockScreenTypeForOpBacklog(key));
    }, [eventKey, showBlockscreen, userEmail, editedBy]);

    /**
     * automatically complete changed if critical change happened
     */
    useEffect(() => {
        const isEditedBySelf = userEmail === editedBy;
        if ([STATES.PendingSolveEditedPlanCritical, STATES.PendingAutomaticUpdateEditedPlanCritical].includes(eventKey) && isEditedBySelf) {
            // If any critical changes happened while user has not yet published the plan, the changes are automatically completed
            socket.emit(
                WS_EVENTS.UserSolveEvent,
                JSON.stringify({
                    action: WS_ACTIONS.UserCompleteChanges,
                    organizationId,
                    sessionId,
                    userEmail,
                    eventId: eventId || stateSolverEvent?.eventId
                })
            );
        }

        if (FAIL_EVENTS.includes(eventKey)) {
            setError(t("OpBacklogPage.errorScheduleCalculation"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [eventCreatedAt, eventKey, editedBy]);

    useEffect(() => {
        if (refreshTrigger) {
            dispatch(loadStateAction());
        }
    }, [refreshTrigger]);

    useEffect(() => {
        if (isResolved(saveStatus)) {
            setMessage(t("OpManagement.saveSuccess"));
            setTimeout(() => setMessage(null), 5 * 1000); // @todo #14763 to be improved

            // reset status
            dispatch(clearSaveEditOpStatusAction());
        }
        if (isRejected(saveStatus) && saveError !== CONFLICT) {
            setError(t("OpManagement.saveError"));
            setTimeout(() => setError(""), 5 * 1000);
        }
    }, [saveStatus]);

    /**
     * Handler for open the details layer
     *
     * @param {object} object Properties wrapper
     * @param {string} object.id - The id of the appointment
     */
    const handleOpenDetails = ({id}) => {
        dispatch(loadDetailOpAction(organizationId, id));
        setOpenDetails(true);
    };
    const handleClosePopover = () => {
        setOpenDetails(false);
    };

    const handleOpenEnablePrint = () => {
        if (!openEnablePrint) {
            dispatch(loadPrintDatesAction(organizationId));
            setOpenEnablePrint(true);
        } else {
            setOpenEnablePrint(false);
        }
    };
    const handleCloseEnablePrint = () => {
        setOpenEnablePrint(false);
    };

    const handleToggleActionMenubarWidth = () => {
        setShowFullActionMenubar(!showFullActionMenubar);
    };

    const headerItems = [
        <ViewSwitch
            isChecked={isBacklogView}
            key="view-switch"
            labels={{left: t("OpManagementPage.backlog"), right: ""}}
            title={t("OpManagementPage.backlog")}
            onToggle={() => dispatch(toggleBacklogView())}
        />
    ];
    const actions = [];
    if (isGranted(PERMISSION.MODIFY_PRINTDATES)) {
        actions.push({
            iconName: "AllowPrintIcon",
            disabled: false,
            handler: handleOpenEnablePrint,
            translationKey: t("OpBacklogPage.enablePrinting"),
            isOpened: openEnablePrint
        });
    }

    return (
        <Page
            fullActionMenubar={showFullActionMenubar}
            fullCanvas
            headerItems={headerItems}
            name="OpBacklogPage"
            organizationId={organizationId}
            title={t("OpBacklogPage.title")}
        >
            {error && <Message message={error} severity="error" />}
            {message && <Message message={message} />}
            <OpBacklogView isBlockscreenVisible={Boolean(blockScreenType)} onRowClick={handleOpenDetails} />
            <ActionMenubar actions={actions} showFullActionMenubar={showFullActionMenubar} onToggleWidth={handleToggleActionMenubarWidth} />
            {openEnablePrint && (
                <DetailRight fullActionMenubar={showFullActionMenubar} open={openEnablePrint} onClose={handleCloseEnablePrint}>
                    <EnablePrintLayer />
                </DetailRight>
            )}
            {openDetails && <OpDetailsPopover handleClosePopover={handleClosePopover} isPopoverOpen={openDetails} />}
            <Backdrop className={classes.backdrop} open={Boolean(blockScreenType)}>
                <BlockedScreen type={blockScreenType} />
            </Backdrop>
        </Page>
    );
};
export default OpBacklogPage;
